


@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    /**
     * colors
     */
  
    /* gradient */
  
    --bg-gradient-onyx: linear-gradient(
      to bottom right,
      hsl(240, 1%, 25%) 3%,
      hsl(0, 0%, 19%) 97%
    );
    --bg-gradient-jet: linear-gradient(
        to bottom right,
        hsla(240, 1%, 18%, 0.251) 0%,
        hsla(240, 2%, 11%, 0) 100%
      ),
      hsl(240, 2%, 13%);
    --bg-gradient-yellow-1: linear-gradient(
      to bottom right,
      hsl(45, 100%, 71%) 0%,
      hsla(36, 100%, 69%, 0) 50%
    );
    --bg-gradient-yellow-2: linear-gradient(
        135deg,
        hsla(45, 100%, 71%, 0.251) 0%,
        hsla(35, 100%, 68%, 0) 59.86%
      ),
      hsl(240, 2%, 13%);
    --border-gradient-onyx: linear-gradient(
      to bottom right,
      hsl(0, 0%, 25%) 0%,
      hsla(0, 0%, 25%, 0) 50%
    );
    --text-gradient-yellow: linear-gradient(
      to right,
      hsl(45, 100%, 72%),
      hsl(35, 100%, 68%)
    );
  
    /* solid */
  
    --jet: hsl(0, 0%, 22%);
    --onyx: hsl(240, 1%, 17%);
    --eerie-black-1: hsl(240, 2%, 13%);
    --eerie-black-2: hsl(240, 2%, 12%);
    --smoky-black: hsl(0, 0%, 7%);
    --white-1: hsl(0, 0%, 100%);
    --white-2: hsl(0, 0%, 98%);
    --orange-yellow-crayola: hsl(45, 100%, 72%);
    --vegas-gold: hsl(45, 54%, 58%);
    --light-gray: hsl(0, 0%, 84%);
    --light-gray-70: hsla(0, 0%, 84%, 0.7);
    --bittersweet-shimmer: hsl(0, 43%, 51%);
  
    /**
     * typography
     */
  
    /* font-family */
    --ff-poppins: "Poppins", sans-serif;
  
    /* font-size */
    --fs-1: 24px;
    --fs-2: 18px;
    --fs-3: 17px;
    --fs-4: 16px;
    --fs-5: 15px;
    --fs-6: 14px;
    --fs-7: 13px;
    --fs-8: 11px;
  
    /* font-weight */
    --fw-300: 300;
    --fw-400: 400;
    --fw-500: 500;
    --fw-600: 600;
  
    /**
     * shadow
     */
  
    --shadow-1: -4px 8px 24px hsla(0, 0%, 0%, 0.25);
    --shadow-2: 0 16px 30px hsla(0, 0%, 0%, 0.25);
    --shadow-3: 0 16px 40px hsla(0, 0%, 0%, 0.25);
    --shadow-4: 0 25px 50px hsla(0, 0%, 0%, 0.15);
    --shadow-5: 0 24px 80px hsla(0, 0%, 0%, 0.25);
  
    /**
     * transition
     */
  
    --transition-1: 0.25s ease;
    --transition-2: 0.5s ease-in-out;
  }
  
  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  
  *,
  *::before,
  *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  a {
    text-decoration: none;
  }
  
  li {
    list-style: none;
  }
  
  .info_more-btn{
    position:absolute;
    top: 0px;
    right: 0px;
    background: var(--bg-gradient-yellow-1);
  }
  
  img,
  ion-icon,
  a,
  button,
  time,
  span {
    display: block;
  }
  
  button {
    font: inherit;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
  }
  
  input,
  textarea {
    display: block;
    width: 100%;
    background: none;
    font: inherit;
  }
  
  ::selection {
    background: var(--orange-yellow-crayola);
    color: var(--smoky-black);
  }
  
  :focus {
    outline-color: var(--orange-yellow-crayola);
  }
  
  html {
    font-family: var(--ff-poppins);
  }
  
  body {
    background: var(--smoky-black);
  }
  
  
  
  .center-align {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .width-per-100 {
    width: 100%;
  }
  
  .flex-end {
    justify-content: flex-end;
  }
  .hide{
    display: none;
  }
  .visible{
    display: block;
  }
  nav {
    position: sticky;
    top: 0px;
    z-index: 100;
  }
  
  .navbar-style {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3%;
  }
  
  .sidebar,
  article,
  .outlet,
  .navbar-style {
    background: var(--eerie-black-2);
    border: 1px solid var(--jet);
    border-radius: 20px;
    padding: 15px;
    box-shadow: var(--shadow-1);
    z-index: 1;
  }
  
  .navbar-style{
    padding: 0px;
  
  }
  
  .separator {
    width: 100%;
    height: 1px;
    background: var(--jet);
    margin: 16px 0;
  }
  
  .icon-box {
    position: relative;
    background: var(--border-gradient-onyx);
    width: 30px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: var(--orange-yellow-crayola);
    box-shadow: var(--shadow-1);
    z-index: 1;
  }
  
  .icon-box::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: var(--eerie-black-1);
    border-radius: inherit;
    z-index: -1;
  }
  
  .icon-box ion-icon {
    --ionicon-stroke-width: 35px;
  }
  
  article {
    display: none;
  }
  
  article.active {
    display: block;
    animation: fade 0.5s ease backwards;
  }
  
  @keyframes fade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .h2,
  .h3,
  .h4,
  .h5 ,h6{
    color: var(--white-2);
    text-transform: capitalize;
  }
  
  .h2 {
    font-size: var(--fs-1);
  }
  
  .h3 {
    font-size: var(--fs-2);
  }
  
  .h4 {
    font-size: var(--fs-4);
  }
  
  .h5 {
    font-size: var(--fs-7);
    font-weight: var(--fw-500);
  }
  
  .article-title {
    position: relative;
    padding-bottom: 7px;
  }
  
  .article-title::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 30px;
    height: 3px;
    background: var(--text-gradient-yellow);
    border-radius: 3px;
  }
  
  .has-scrollbar::-webkit-scrollbar {
    width: 5px; /* for vertical scrollbar */
    height: 5px; /* for horizontal scrollbar */
  }
  
  .has-scrollbar::-webkit-scrollbar-track {
    background: var(--onyx);
    border-radius: 5px;
  }
  
  .has-scrollbar::-webkit-scrollbar-thumb {
    background: var(--orange-yellow-crayola);
    border-radius: 5px;
  }
  
  .has-scrollbar::-webkit-scrollbar-button {
    width: 20px;
  }
  
  .content-card {
    position: relative;
    background: var(--border-gradient-onyx);
    padding: 15px;
    padding-top: 45px;
    border-radius: 14px;
    box-shadow: var(--shadow-2);
    cursor: pointer;
    z-index: 1;
  }
  
  .content-card::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: var(--bg-gradient-jet);
    border-radius: inherit;
    z-index: -1;
  }
  
  
  
  main {
    margin: 15px 12px;
    margin-bottom: 75px;
    min-width: 259px;
  }
  
  
  .sidebar {
    margin-bottom: 15px;
    /* max-height: 112px; */
    overflow: hidden;
    transition: var(--transition-2);
    position: relative;
  }
  
  .sidebar.active {
    max-height: 405px;
  }
  
  .sidebar-info {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }
  
  .avatar-box {
    background: var(--bg-gradient-onyx);
    border-radius: 20px;
    width: fit-content;
  }
  
  
  .info-content .name {
    color: var(--white-2);
    font-size: var(--fs-3);
    font-weight: var(--fw-500);
    letter-spacing: -0.25px;
    margin-bottom: 10px;
  }
  
  .info-content .title {
    color: var(--white-1);
    background: var(--onyx);
    font-size: var(--fs-5);
    font-weight: var(--fw-300);
    width: max-content;
    padding: 3px 12px;
    border-radius: 8px;
  }
  .info-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .info_more-btn {
    position: absolute;
    /* top: -15px; */
    /* right: -15px; */
    border-radius: 0 15px;
    font-size: 13px;
    color: var(--orange-yellow-crayola);
    background: var(--bg-gradient-yellow-2);
    padding: 10px;
    box-shadow: var(--shadow-2);
    transition: var(--transition-1);
    z-index: 1;
    border: 1px solid var(--jet);
  
  }
  
  .info_more-btn:hover{
    cursor: pointer;
  }
  
  /* .info_more-btn::before {
    content: "";
    position: absolute;
    inset: 1px;
    border-radius: inherit;
    /* background: var(--bg-gradient-jet); */
    /* transition: var(--transition-1); */
    /* z-index: -1; */
  /* } */
  .inline-block {
    display: inline-block;
  }
  
  /* .info_more-btn:hover,
  .info_more-btn:focus {
    background: var(--bg-gradient-yellow-1);
  }
  
  .info_more-btn:hover::before,
  .info_more-btn:focus::before {
    background: var(--bg-gradient-yellow-2);
  } */
  
  
  
  .sidebar-info_more {
    opacity: 0;
    visibility: hidden;
    transition: var(--transition-2);
  }
  
  .sidebar.active .sidebar-info_more {
    opacity: 1;
    visibility: visible;
  }
  
  .contacts-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
    margin-top: 20px;
  }
  
  .contact-item {
    min-width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
  }
  
  .contact-info {
    max-width: calc(100% - 46px);
    width: calc(100% - 46px);
  }
  
  .contact-title {
    color: var(--light-gray-70);
    font-size: var(--fs-8);
    text-transform: uppercase;
    margin-bottom: 2px;
  }
  
  .contact-info :is(.contact-link, time, address) {
    color: var(--white-2);
    font-size: var(--fs-7);
  }
  
  .contact-info address {
    font-style: normal;
  }
  
  .social-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding-bottom: 4px;
    padding-left: 7px;
  }
  
  .social-item .social-link {
    color: var(--light-gray-70);
    font-size: 18px;
  }
  
  .social-item .social-link:hover {
    color: var(--light-gray);
  }
  
  /*-----------------------------------*\
    #NAVBAR
  \*-----------------------------------*/
  
  .navbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: hsla(240, 1%, 17%, 0.75);
    backdrop-filter: blur(10px);
    border: 1px solid var(--jet);
    border-radius: 12px 12px 0 0;
    box-shadow: var(--shadow-2);
    z-index: 5;
  }
  
  .detail-work-button {
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    padding: 5px;
    margin-top: 5px;
  }
  
  .detail-work-button:hover {
    background-color: hsl(45, 100%, 72%);
    color: black;
  }
  .navbar-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
  }
  
  .navbar-link {
    color: var(--light-gray);
    font-size: var(--fs-8);
    padding: 20px 7px;
    transition: color var(--transition-1);
    text-transform: capitalize;
  }
  
  .navbar-link:hover,
  .navbar-link:focus {
    color: var(--light-gray-70);
  }
  
  .navbar-link.active {
    color: var(--orange-yellow-crayola);
  }
  
  /*-----------------------------------*\
    #ABOUT
  \*-----------------------------------*/
  
  .about .article-title {
    margin-bottom: 15px;
  }
  
  .about-text {
    color: var(--light-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
    line-height: 1.6;
  }
  
  .about-text p {
    margin-bottom: 15px;
  }
  
  /**
   * #service 
   */
  .flex {
    display: flex;
  }
  
  .service {
    margin-bottom: 35px;
  }
  
  .service-title {
    margin-bottom: 20px;
  }
  
  .service-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .reactIcon {
    color: aqua;
  }
  .cssIcon {
    color: orange;
  }
  .awsIcon {
    color: white;
  }
  
  .tech-stack {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 1em;
    flex-wrap: wrap;
    margin-top: .5em;
  }
  
  .service-item {
    position: relative;
    background: var(--border-gradient-onyx);
    padding: 20px;
    border-radius: 14px;
    box-shadow: var(--shadow-2);
    z-index: 1;
  }
  .stack-icon {
    width:3em;
    height: 3em;
  }
  
  .service-item::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: var(--bg-gradient-jet);
    border-radius: inherit;
    z-index: -1;
  }
  
  .tect-stack-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1em;
  }
  .service-icon-box {
    margin-bottom: 10px;
  }
  
  .service-icon-box img {
    margin: auto;
  }
  
  .service-content-box {
    text-align: center;
  }
  
  .service-item-title {
    margin-bottom: 7px;
  }
  
  .service-item-text {
    color: var(--light-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-3);
    line-height: 1.6;
  }
  
  /**
   * #testimonials 
   */
  
  .testimonials {
    margin-bottom: 30px;
  }
  
  .testimonials-title {
    margin-bottom: 20px;
  }
  
  .testimonials-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    margin: 0 -15px;
    padding: 25px 15px;
    padding-bottom: 35px;
    overflow-x: auto;
    scroll-behavior: smooth;
    overscroll-behavior-inline: contain;
    scroll-snap-type: inline mandatory;
  }
  
  .testimonials-item {
    min-width: 100%;
    scroll-snap-align: center;
  }
  
  .testimonials-avatar-box {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(15px, -25px);
    background: var(--bg-gradient-onyx);
    border-radius: 14px;
    box-shadow: var(--shadow-1);
  }
  
  .testimonials-item-title {
    margin-bottom: 7px;
  }
  
  .testimonials-text {
    color: var(--light-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
    line-height: 1.6;
    display: -webkit-box;
    line-clamp: 4;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  
  /**
   * #testimonials-modal
   */
  
  .modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
    overscroll-behavior: contain;
    z-index: 20;
    pointer-events: none;
    visibility: hidden;
  }
  
  .modal-container::-webkit-scrollbar {
    display: none;
  }
  
  .modal-container.active {
    pointer-events: all;
    visibility: visible;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: hsl(0, 0%, 5%);
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 1;
    transition: var(--transition-1);
  }
  
  .overlay.active {
    opacity: 0.8;
    visibility: visible;
    pointer-events: all;
  }
  
  .testimonials-modal {
    background: var(--eerie-black-2);
    position: relative;
    padding: 15px;
    margin: 15px 12px;
    border: 1px solid var(--jet);
    border-radius: 14px;
    box-shadow: var(--shadow-5);
    transform: scale(1.2);
    opacity: 0;
    transition: var(--transition-1);
    z-index: 2;
  }
  
  .modal-container.active .testimonials-modal {
    transform: scale(1);
    opacity: 1;
  }
  
  .modal-close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    background: var(--onyx);
    border-radius: 8px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white-2);
    font-size: 18px;
    opacity: 0.7;
  }
  
  .modal-close-btn:hover,
  .modal-close-btn:focus {
    opacity: 1;
  }
  
  .modal-close-btn ion-icon {
    --ionicon-stroke-width: 50px;
  }
  
  .modal-avatar-box {
    background: var(--bg-gradient-onyx);
    width: max-content;
    border-radius: 14px;
    margin-bottom: 15px;
    box-shadow: var(--shadow-2);
  }
  
  .modal-img-wrapper > img {
    display: none;
  }
  
  .modal-title {
    margin-bottom: 4px;
  }
  
  .modal-content time {
    font-size: var(--fs-6);
    color: var(--light-gray-70);
    font-weight: var(--fw-300);
    margin-bottom: 10px;
  }
  
  .modal-content p {
    color: var(--light-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
    line-height: 1.6;
  }
  
  /**
   * #clients 
   */
  
  .clients {
    margin-bottom: 15px;
  }
  
  .clients-list {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
    margin: 0 -15px;
    padding: 25px;
    padding-bottom: 25px;
    overflow-x: auto;
    scroll-behavior: smooth;
    overscroll-behavior-inline: contain;
    scroll-snap-type: inline mandatory;
    scroll-padding-inline: 25px;
  }
  
  .clients-item {
    min-width: 50%;
    scroll-snap-align: start;
  }
  
  .clients-item img {
    width: 100%;
    filter: grayscale(1);
    transition: var(--transition-1);
  }
  
  .clients-item img:hover {
    filter: grayscale(0);
  }
  
  
  
  .article-title {
    margin-bottom: 30px;
  }
  
  /**
   * education and experience 
   */
  
  .timeline {
    margin-bottom: 30px;
  }
  
  .timeline .title-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 25px;
  }
  
  .timeline-list {
    font-size: var(--fs-6);
    margin-left: 45px;
  }
  
  .timeline-item {
    position: relative;
  }
  
  .timeline-item:not(:last-child) {
    margin-bottom: 20px;
  }
  
  .timeline-item-title {
    font-size: var(--fs-6);
    line-height: 1.3;
    margin-bottom: 7px;
  }
  
  .timeline-list span {
    color: var(--vegas-gold);
    font-weight: var(--fw-400);
    line-height: 1.6;
  }
  
  .timeline-item:not(:last-child)::before {
    content: "";
    position: absolute;
    top: -25px;
    left: -30px;
    width: 1px;
    height: calc(100% + 50px);
    background: var(--jet);
  }
  
  .timeline-item::after {
    content: "";
    position: absolute;
    top: 5px;
    left: -33px;
    height: 6px;
    width: 6px;
    background: var(--text-gradient-yellow);
    border-radius: 50%;
    box-shadow: 0 0 0 4px var(--jet);
  }
  
  .timeline-text {
    color: var(--light-gray);
    font-weight: var(--fw-500);
    line-height: 1.6;
  }
  
  
  
  .skills-title {
    margin-bottom: 20px;
  }
  
  .skills-list {
    padding: 20px;
  }
  
  .skills-item:not(:last-child) {
    margin-bottom: 15px;
  }
  
  .skill .title-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 8px;
  }
  
  .skill .title-wrapper data {
    color: var(--light-gray);
    font-size: var(--fs-7);
    font-weight: var(--fw-300);
  }
  
  .skill-progress-bg {
    background: var(--jet);
    width: 100%;
    height: 8px;
    border-radius: 10px;
  }
  
  .skill-progress-fill {
    background: var(--text-gradient-yellow);
    height: 100%;
    border-radius: inherit;
  }
  
  
  
  .filter-list {
    display: none;
  }
  
  .filter-select-box {
    position: relative;
    margin-bottom: 25px;
  }
  
  .filter-select {
    background: var(--eerie-black-2);
    color: var(--light-gray);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 12px 16px;
    border: 1px solid var(--jet);
    border-radius: 14px;
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
  }
  
  .filter-select.active .select-icon {
    transform: rotate(0.5turn);
  }
  
  .select-list {
    background: var(--eerie-black-2);
    position: absolute;
    top: calc(100% + 6px);
    width: 100%;
    padding: 6px;
    border: 1px solid var(--jet);
    border-radius: 14px;
    z-index: 2;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: 0.15s ease-in-out;
  }
  
  .filter-select.active + .select-list {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  
  .select-item button {
    background: var(--eerie-black-2);
    color: var(--light-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
    text-transform: capitalize;
    width: 100%;
    padding: 8px 10px;
    border-radius: 8px;
  }
  
  .select-item button:hover {
    --eerie-black-2: hsl(240, 2%, 20%);
  }
  .service-icon-box img{
    width: 4em;
  }
  
  .project-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    margin-bottom: 10px;
  }
  
  .project-item {
    display: none;
  }
  
  .project-item.active {
    display: block;
    animation: scaleUp 0.25s ease forwards;
  }
  
  @keyframes scaleUp {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .project-item > a {
    width: 100%;
  }
  
  .project-img {
    position: relative;
    width: 100%;
    height: 200px;
    border-radius: 16px;
    overflow: hidden;
    margin-bottom: 15px;
  }
  
  .project-img::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
    transition: var(--transition-1);
  }
  
  .project-item > a:hover .project-img::before {
    background: hsla(0, 0%, 0%, 0.5);
  }
  
  .project-item-icon-box {
    --scale: 0.8;
  
    background: var(--jet);
    color: var(--orange-yellow-crayola);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(var(--scale));
    font-size: 20px;
    padding: 18px;
    border-radius: 12px;
    opacity: 0;
    z-index: 1;
    transition: var(--transition-1);
  }
  
  .project-item > a:hover .project-item-icon-box {
    --scale: 1;
    opacity: 1;
  }
  
  .project-item-icon-box ion-icon {
    --ionicon-stroke-width: 50px;
  }
  
  .project-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition-1);
  }
  
  .project-item > a:hover img {
    transform: scale(1.1);
  }
  
  .project-title,
  .project-category {
    margin-left: 10px;
  }
  
  .project-title {
    color: var(--white-2);
    font-size: var(--fs-5);
    font-weight: var(--fw-400);
    text-transform: capitalize;
    line-height: 1.3;
  }
  
  .project-category {
    color: var(--light-gray-70);
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
  }
  
  
  .blog-posts {
    margin-bottom: 10px;
  }
  
  .blog-posts-list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .blog-post-item > a {
    position: relative;
    background: var(--border-gradient-onyx);
    height: 100%;
    box-shadow: var(--shadow-4);
    border-radius: 16px;
    z-index: 1;
  }
  
  .blog-post-item > a::before {
    content: "";
    position: absolute;
    inset: 1px;
    border-radius: inherit;
    background: var(--eerie-black-1);
    z-index: -1;
  }
  
  .blog-banner-box {
    width: 100%;
    height: 200px;
    border-radius: 12px;
    overflow: hidden;
  }
  
  .blog-banner-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition-1);
  }
  
  .blog-post-item > a:hover .blog-banner-box img {
    transform: scale(1.1);
  }
  
  .blog-content {
    padding: 15px;
  }
  
  .blog-meta {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    margin-bottom: 10px;
  }
  
  .blog-meta :is(.blog-category, time) {
    color: var(--light-gray-70);
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
  }
  
  .blog-meta .dot {
    background: var(--light-gray-70);
    width: 4px;
    height: 4px;
    border-radius: 4px;
  }
  
  .blog-item-title {
    margin-bottom: 10px;
    line-height: 1.3;
    transition: var(--transition-1);
  }
  
  .blog-post-item > a:hover .blog-item-title {
    color: var(--orange-yellow-crayola);
  }
  
  .blog-text {
    color: var(--light-gray);
    font-size: var(--fs-6);
    font-weight: var(--fw-300);
    line-height: 1.6;
  }
  
  
  .mapbox {
    position: relative;
    height: 250px;
    width: 100%;
    border-radius: 16px;
    margin-bottom: 30px;
    border: 1px solid var(--jet);
    overflow: hidden;
  }
  
  .mapbox figure {
    height: 100%;
  }
  
  .mapbox iframe {
    width: 100%;
    height: 100%;
    border: none;
    filter: grayscale(1) invert(1);
  }
  
  .contact-form {
    margin-bottom: 10px;
  }
  
  .form-title {
    margin-bottom: 20px;
  }
  
  .input-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 25px;
    margin-bottom: 25px;
  }
  
  .form-input {
    color: var(--white-2);
    font-size: var(--fs-6);
    font-weight: var(--fw-400);
    padding: 13px 20px;
    border: 1px solid var(--jet);
    border-radius: 14px;
    outline: none;
  }
  
  .form-input::placeholder {
    font-weight: var(--fw-500);
  }
  
  .form-input:focus {
    border-color: var(--orange-yellow-crayola);
  }
  
  textarea.form-input {
    min-height: 100px;
    height: 120px;
    max-height: 200px;
    resize: vertical;
    margin-bottom: 25px;
  }
  
  textarea.form-input::-webkit-resizer {
    display: none;
  }
  
  .form-input:focus:invalid {
    border-color: var(--bittersweet-shimmer);
  }
  
  .form-btn {
    position: relative;
    width: 100%;
    background: var(--border-gradient-onyx);
    color: var(--orange-yellow-crayola);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 13px 20px;
    border-radius: 14px;
    font-size: var(--fs-6);
    text-transform: capitalize;
    box-shadow: var(--shadow-3);
    z-index: 1;
    transition: var(--transition-1);
  }
  
  .form-btn::before {
    content: "";
    position: absolute;
    inset: 1px;
    background: var(--bg-gradient-jet);
    border-radius: inherit;
    z-index: -1;
    transition: var(--transition-1);
  }
  
  .form-btn ion-icon {
    font-size: 16px;
  }
  
  .form-btn:hover {
    background: var(--bg-gradient-yellow-1);
  }
  
  .form-btn:hover::before {
    background: var(--bg-gradient-yellow-2);
  }
  
  .form-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .form-btn:disabled:hover {
    background: var(--border-gradient-onyx);
  }
  
  .form-btn:disabled:hover::before {
    background: var(--bg-gradient-jet);
  }
  
  .work-card li{
    font-size: .8em;
    list-style-type:disc;
    list-style-position: inside;
  
  }
  
  .work-card li {
    list-style-type: none; /* Hide default bullet */
    padding-left: 1.5em; /* Adjust padding as needed */
  }
  
  .work-card li {
    position: relative; /* Required for absolute positioning */
    padding-left: 1em; /* Space between bullet and content */
  }
  
  .work-card li::before {
    content: '●'; /* Use the filled circle character */
    color:var(--orange-yellow-crayola); /* Fill color */
    font-size: .8em; /* Adjust size as needed */
    position: absolute; /* Position relative to li */
    left: .1em; /* Position at the start of li */
    top: .1em;
    
  }
  
  .rolediscriptiom{
    font-size: .8em;
  }
  .work-card p{
    font-size: .8em;
  }
  
  
  .work-card {
    position: relative;
    /* background: var(--border-gradient-onyx); */
    padding: .8em;
    border-radius: 14px;
    box-shadow: var(--shadow-2);
    border: 1px solid var(--jet);
    z-index: 1;
    margin-block: .4em;
  }
  
  .project{
    color: azure;
    font-size:.8em;
    border-radius: 15px 0px;
      color: var(--orange-yellow-crayola);
      background: var(--bg-gradient-yellow-2);
      padding: .4em;
      box-shadow: var(--shadow-2);
      transition: var(--transition-1);
      z-index: 1;
      border: 2px solid var(--jet);
      width: fit-content;
      margin-top: .5em;
      font-weight: 500;
  }
  
  .worklist span{
    display: inline;
  }
  
  .masked-text {
    font-size: 1em;
    font-weight: bold;
    color: transparent;
    background: url('https://upload.wikimedia.org/wikipedia/commons/thumb/a/a7/React-icon.svg/1200px-React-icon.svg.png') no-repeat center center;
    background-size: cover;
    -webkit-background-clip: text;
    background-clip: text;
  }
  
  .timeline-item .heading{
   color: lightskyblue;
   font-weight: 700;
  }
  
  .awsCSS{
    background-color: white;
    padding: .2em;
    border-radius: 50%;
  }
  
  .hightlight{
  
    color: lightgreen;
    font-size: 1em;
    font-weight: 600;}
  
    .landingIntroduction{
      font-size: 1.2em;
      color:lightyellow;
      font-weight: 600;
    }
    
    .form-btn{
  
      text-align: center;
      font-size: 1em;
      border: 1px solid var(--jet);
  
    }
  
    .form-btn span{
      display: flex;
      gap: .7em;
    }
  
    .input-form-wrapper{
      display: flex;
    }
  
    input:-webkit-autofill,
  textarea:-webkit-autofill {
    background-color: black !important
  }
  
  .Toastify__toast-container{
    position: absolute !important;
    right: 5px !important;
    left: auto !important;
    width: fit-content !important;
    border-radius: 8px !important;
  }
  
  .Toastify__toast--success {
    background-color: #4caf50; /* Custom background color for success toasts */
  }
  
  .Toastify__toast--error {
    background-color: #f44336; /* Custom background color for error toasts */
  }
  
  
    /* .form-input:-internal-autofill-selected {
      background-color: 'none' !important;
    } */
  
  
  @media (max-width: 450px) {
    .SecondContainer {
      flex-direction: column;
    }
  
    .Toastify__toast-icon{
      margin-inline:.6em !important;
      width:1.7em !important;
    }
    .navbar-style {
      width: 100%;
    }
  .Toastify__toast-container--top-right{
    top: 15px  !important;
    }
    .Toastify__toast-body{
    padding:6px 6px 6px 0px !important
  
    }
  
    .Toastify__toast {
      width: fit-content; /* Adjust the width as needed */
      height: fit-content; /* Adjust the height as needed, or set a fixed value */
       /* Ensure a minimum height if needed */
      padding:.2em .2em .2em 0em !important; /* Add padding if needed */
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Custom box-shadow */
      border-radius: 8px !important; /* Rounded corners */
      font-size: .7em; /* Custom font size */
      border: 1px solid white;
      min-height:fit-content !important
  }
    nav{
      justify-content: center !important;
    }
  
    .sidebar-header{
      display:flex;
      column-gap: 40px;
    }
  
    .info_more-btn{
      display: block;
    }
  
    .dropdownArrow{
      display: block;
    }
    .DropDowntext {
      display: none;
    }
  
    .landingIntroduction{
      font-size: 1.1em;
    }
  
    .tech-stack {
      justify-content: center;
    }
    .fontSize{
      font-size:16px
    }
  
    .workFont{
      font-size: 16px;
    }
  
    .input-form-wrapper{
      flex-direction: column;
      gap: 1em;
      margin-bottom: 1em;
    }
   
  
  }
  /**
     * client
     */
  
  /**
   * responsive larger than 450px screen
   */
  
  @media (min-width: 450px) {
    /**
     * client
     */
     .sidebar-header{
      display:flex;
      column-gap: 40px;
  
    }
  
    .fontSize{
      font-size:14px
    }
  
     .dropdownArrow{
      display: none;
    }
    .DropDowntext {
      display: block;
    }
  
  
    .info_more-btn{
      display: block;
    }
  
     nav{
      justify-content: center ;
    }
  
    .navbar-style {
      width: 100%;
    }
  
    .SecondContainer {
      flex-direction: column;
    }
  
    .clients-item {
      min-width: calc(33.33% - 10px);
    }
  
    .tech-stack {
      justify-content: center;
    }
  
    /**
     * #PORTFOLIO, BLOG 
     */
  
    .project-img,
    .blog-banner-box {
      height: auto;
    }
  
    .input-form-wrapper{
      flex-direction: column;
      gap: 1em;
      margin-bottom: 1em;
    }
  
    .Toastify__toast {
      width: fit-content; /* Adjust the width as needed */
      height: fit-content; /* Adjust the height as needed, or set a fixed value */
       /* Ensure a minimum height if needed */
      padding:.2em .2em .2em 0em !important; /* Add padding if needed */
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Custom box-shadow */
      border-radius: 8px !important; /* Rounded corners */
      font-size: .8em; /* Custom font size */
      border: 1px solid white;
      min-height:fit-content !important
  }
  
    
  }
  
  /**
   * responsive larger than 580px screen
   */
  
  @media (min-width: 580px) {
    /**
     * CUSTOM PROPERTY
     */
  
    :root {
      /**
       * typography
       */
  
      --fs-1: 32px;
      --fs-2: 24px;
      --fs-3: 26px;
      --fs-4: 18px;
      --fs-6: 15px;
      --fs-7: 15px;
      --fs-8: 12px;
    }
  
    .fontSize{
      font-size:15px
    }
    /**
     * #REUSED STYLE
     */
  
    .sidebar,
    article,
    .outlet{
      width: 520px;
      margin-inline: auto;
      padding: 30px;
    }
  
    .navbar-style{
      width: 520px;
    }
  
    
    .article-title {
      font-weight: var(--fw-600);
      padding-bottom: 15px;
    }
  
    .article-title::after {
      width: 40px;
      height: 5px;
    }
  
    .icon-box {
      width: 48px;
      height: 48px;
      border-radius: 12px;
      font-size: 18px;
    }
  
    /**
     * #MAIN
     */
  
    main {
      /* margin-top: 60px; */
      margin-bottom: 100px;
    }
  
    /**
     * #SIDEBAR
     */
  
    .sidebar {
      /* max-height: 180px; */
      margin-bottom: 30px;
    }
  
    .sidebar.active {
      max-height: 584px;
    }
  
    .sidebar-info {
      gap: 25px;
    }
  
    .avatar-box {
      border-radius: 30px;
    }
  
    .avatar-box img {
      width: 120px;
    }
  
    .info-content .name {
      margin-bottom: 15px;
    }
  
    .info-content .title {
      padding: 5px 18px;
    }
  
    .info_more-btn {
      /* top: -30px; */
      /* right: -30px; */
      padding: 10px 15px;
    }
  
    .DropDowntext {
      display: block;
      font-size: var(--fs-8);
    }
  
    .info_more-btn ion-icon {
      display: none;
    }
  
    .separator {
      margin: 32px 0;
    }
  
    .contacts-list {
      gap: 20px;
    }
  
    .contact-info {
      max-width: calc(100% - 64px);
      width: calc(100% - 64px);
    }
  
    /**
     * #NAVBAR
     */
  
    .navbar {
      border-radius: 20px 20px 0 0;
    }
  
    .navbar-list {
      gap: 20px;
    }
  
    .navbar-link {
      --fs-8: 14px;
    }
  
    .tech-stack {
      justify-content: flex-start;
    }
  
    /**
     * #ABOUT
     */
  
    .about .article-title {
      margin-bottom: 20px;
    }
  
    .about-text {
      margin-bottom: 40px;
    }
  
    /* service */
  
    .service-item {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 1.2em;
      padding: 30px;
    }
  
    .service-icon-box {
      margin-bottom: 0;
      margin-top: 5px;
    }
  
  
  
    .service-content-box {
      text-align: left;
    }
  
    /* testimonials */
  
    .testimonials-title {
      margin-bottom: 25px;
    }
  
    .testimonials-list {
      gap: 30px;
      margin: 0 -30px;
      padding: 30px;
      padding-bottom: 35px;
    }
  
    .content-card {
      padding: 30px;
      padding-top: 25px;
    }
  
    .testimonials-avatar-box {
      transform: translate(30px, -30px);
      border-radius: 20px;
    }
  
    .testimonials-avatar-box img {
      width: 80px;
    }
  
    .testimonials-item-title {
      margin-bottom: 10px;
      margin-left: 95px;
    }
  
    .testimonials-text {
      line-clamp: 2;
      -webkit-line-clamp: 2;
    }
  
    /* testimonials modal */
  
    .modal-container {
      padding: 20px;
    }
  
    .testimonials-modal {
      display: flex;
      justify-content: flex-start;
      align-items: stretch;
      gap: 25px;
      padding: 30px;
      border-radius: 20px;
    }
  
    .modal-img-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  
    .modal-avatar-box {
      border-radius: 18px;
      margin-bottom: 0;
    }
  
    .modal-avatar-box img {
      width: 65px;
    }
  
    .modal-img-wrapper > img {
      display: block;
      flex-grow: 1;
      width: 35px;
    }
  
    /* clients */
  
    .clients-list {
      gap: 50px;
      margin: 0 -30px;
      padding: 45px;
      scroll-padding-inline: 45px;
    }
  
    .clients-item {
      min-width: calc(33.33% - 35px);
    }
  
    /**
     * #RESUME
     */
  
    .timeline-list {
      margin-left: 65px;
    }
  
    .timeline-item:not(:last-child)::before {
      left: -40px;
    }
  
    .timeline-item::after {
      height: 8px;
      width: 8px;
      left: -43px;
    }
  
    .skills-item:not(:last-child) {
      margin-bottom: 25px;
    }
  
    /**
     * #PORTFOLIO, BLOG
     */
  
    .project-img,
    .blog-banner-box {
      border-radius: 16px;
    }
  
    .blog-posts-list {
      gap: 30px;
    }
  
    .blog-content {
      padding: 25px;
    }
  
    /**
     * #CONTACT
     */
  
    .mapbox {
      height: 380px;
      border-radius: 18px;
    }
  
    .input-wrapper {
      gap: 30px;
      margin-bottom: 30px;
    }
  
    .form-input {
      padding: 15px 20px;
    }
  
    textarea.form-input {
      margin-bottom: 30px;
    }
  
    .form-btn {
      --fs-6: 16px;
      padding: 16px 20px;
    }
  
    .form-btn ion-icon {
      font-size: 18px;
    }
  
    .SecondContainer {
      flex-direction: column;
    }
    .input-form-wrapper{
      flex-direction: row;
      gap: 2em;
      margin-bottom: 1em;
    }
  }
  
  /**
   * responsive larger than 768px screen
   */
  
  @media (min-width: 768px) {
    /**
     * REUSED STYLE
     */
  
     .fontSize{
      font-size:16px
    }
    .sidebar,
    article,
    .outlet {
      width: 700px;
    }
  
  
    .has-scrollbar::-webkit-scrollbar-button {
      width: 100px;
    }
  
    .SecondContainer {
      flex-direction: column;
    }
  
    .navbar-style {
      width: 700px;
    }
    nav{
      justify-content:center;
    }
  
    /**
     * SIDEBAR
     */
  
    .contacts-list {
      grid-template-columns: 1fr 1fr;
      gap: 30px 15px;
    }
  
    /**
     * NAVBAR
     */
  
    .navbar-link {
      --fs-8: 15px;
    }
  
    /**
     * ABOUT
     */
  
    /* testimonials modal */
  
    .testimonials-modal {
      gap: 35px;
      max-width: 680px;
    }
  
    .modal-avatar-box img {
      width: 80px;
    }
  
    /**
     * PORTFOLIO
     */
  
    .article-title {
      padding-bottom: 20px;
    }
  
    .filter-select-box {
      display: none;
    }
  
    .filter-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 25px;
      padding-left: 5px;
      margin-bottom: 30px;
    }
  
    .filter-item button {
      color: var(--light-gray);
      font-size: var(--fs-5);
      transition: var(--transition-1);
    }
  
    .filter-item button:hover {
      color: var(--light-gray-70);
    }
  
    .filter-item button.active {
      color: var(--orange-yellow-crayola);
    }
  
    /* portfolio and blog grid */
  
    .project-list,
    .blog-posts-list {
      grid-template-columns: 1fr 1fr;
    }
  
    /**
     * CONTACT
     */
  
    .input-wrapper {
      grid-template-columns: 1fr 1fr;
    }
  
    .form-btn {
      width: max-content;
      margin-left: auto;
    }
  }
  
  /**
   * responsive larger than 1024px screen
   */
  
  @media (min-width: 1024px) {
    /**
     * CUSTOM PROPERTY
     */
  
     .fontSize{
      font-size:18px
    }
  
    :root {
      /**
      * shadow
      */
  
      --shadow-1: -4px 8px 24px hsla(0, 0%, 0%, 0.125);
      --shadow-2: 0 16px 30px hsla(0, 0%, 0%, 0.125);
      --shadow-3: 0 16px 40px hsla(0, 0%, 0%, 0.125);
    }
  
    /**
     * REUSED STYLE
     */
  
    .sidebar,
    article,
    .outlet {
      width: 950px;
      box-shadow: var(--shadow-5);
    }
  
    /* .navbar-style {
      width: 950px;
    } */
  
    
    /**
     * MAIN 
     */
  
    main {
      margin-bottom: 60px;
    }
  
    .main-content {
      position: relative;
      width: max-content;
      margin: auto;
    }
  
    main {
      max-width: 1024px;
      margin-inline: auto;
      /* display: flex; */
      justify-content: center;
      align-items: stretch;
      gap: 25px;
    }
  
    
  
    .navbar-style{
      width: 950px;
    }
  
    /**
     * NAVBAR
     */
  
    .navbar {
      position: absolute;
      bottom: auto;
      top: 0;
      left: auto;
      right: 0;
      width: max-content;
      border-radius: 0 20px;
      padding: 0 20px;
      box-shadow: none;
    }
  
    .navbar-list {
      gap: 30px;
      padding: 0 20px;
    }
  
    .navbar-link {
      font-weight: var(--fw-500);
    }
  
    /**
     * ABOUT
     */
  
    /* service */
  
    .service-list {
      grid-template-columns: 1fr 1fr;
      gap: 20px 25px;
    }
  
    /* testimonials */
  
    .testimonials-item {
      min-width: calc(50% - 15px);
    }
  
    /* clients */
  
    .clients-item {
      min-width: calc(25% - 38px);
    }
  
    /**
     * PORTFOLIO
     */
  
    .project-list {
      grid-template-columns: repeat(3, 1fr);
    }
  
    /**
     * BLOG
     */
  
    .blog-banner-box {
      height: 230px;
    }
  }
  
  /**
   * responsive larger than 1250px screen
   */
  
  @media (min-width: 1250px) {
    /**
     * RESET
     */
  
    
    .visible,.hide{
      display: block;
    }
  
     .info_more-btn{
      display: none;
    }
     .sidebar-header{
      display:flex;
      flex-direction: row;
      align-items: center;
    }
     .SecondContainer {
      flex-direction: row;
    }
  
    nav{
      justify-content:flex-end;
    }
  
    body::-webkit-scrollbar {
      width: 20px;
    }
  
    body::-webkit-scrollbar-track {
      background: var(--smoky-black);
    }
  
    body::-webkit-scrollbar-thumb {
      border: 5px solid var(--smoky-black);
      background: hsla(0, 0%, 100%, 0.1);
      border-radius: 20px;
      box-shadow: inset 1px 1px 0 hsla(0, 0%, 100%, 0.11),
        inset -1px -1px 0 hsla(0, 0%, 100%, 0.11);
    }
  
    body::-webkit-scrollbar-thumb:hover {
      background: hsla(0, 0%, 100%, 0.15);
    }
  
    body::-webkit-scrollbar-button {
      height: 60px;
    }
  
    /**
     * REUSED STYLE
     */
  
    .sidebar,
    article,
    .outlet {
      width: auto;
    }
  
    article {
      min-height: 100%;
    }
  
    /**
     * MAIN
     */
  
    main {
      max-width: 1200px;
      margin-inline: auto;
      /* display: flex; */
      justify-content: center;
      align-items: stretch;
      gap: 25px;
    }
  
    .main-content {
      width: 100%;
      margin: 0;
    }
  
    .navbar-style{
      width: 100%;
    }
    /**
     * SIDEBAR
     */
  
    .sidebar {
      position: sticky;
      top: 60px;
      max-height: max-content;
      height: 100%;
      margin-bottom: 0;
      /* padding-top: 60px; */
      z-index: 1;
    }
  
    .sidebar-info {
      flex-direction: column;
    }
  
    .avatar-box img {
      width: 150px;
    }
  
    .info-content .name {
      white-space: nowrap;
      text-align: center;
    }
  
    .info-content .title {
      margin: auto;
    }
  
  
    .info_more-btn {
      display: none;
    }
  
    .sidebar-info_more {
      opacity: 1;
      visibility: visible;
    }
  
    .contacts-list {
      grid-template-columns: 1fr;
    }
  
    .contact-info :is(.contact-link) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  
    .contact-info :is(.contact-link, time, address) {
      --fs-7: 14px;
      font-weight: var(--fw-300);
    }
  
    .separator:last-of-type {
      margin: 15px 0;
      opacity: 0;
    }
  
    .social-list {
      justify-content: center;
    }
  
    /**
       * RESUME
       */
  
    .timeline-text {
      max-width: 700px;
    }
    .fontSize{
      font-size:20px
    }
  }


  input{
	outline: none !important;
	border-radius: 6px !important;
	padding: 4px !important;
	padding-left: 6px !important;
	font-size: 16px !important;
	color: #d0d0d0 !important;
	background:transparent ;
}
  
.required-label::after {
  content: " *";
  color: red;
}

label{
  font-size: 16px;
  font-weight:bold ;
color:#969696;
margin-bottom: 4px !important;

}

.cutomCompoent{
  border: 2px solid ;

}
  